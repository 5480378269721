import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { formatLeagueName } from './utils'; // Importing from utils
import './EventDetails.css';

const EventDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedDate = queryParams.get('date') || new Date().toISOString().split('T')[0];
    const { eventStats, eventLineup, game, prediction } = location.state || {};

    const [videoError, setVideoError] = useState(false);
    const [isUpcoming, setIsUpcoming] = useState(false);
    const [isNBAGame, setIsNBAGame] = useState(false);

    // Define getYouTubeEmbedUrl at the top level so it's available everywhere
    const getYouTubeEmbedUrl = (url) => {
        if (!url) return null;
        const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/);
        return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : null;
    };

    // Scroll to the top of the page on navigation
    useEffect(() => {
        window.scrollTo(0, 0);
        
        // Check if this is an upcoming game
        if (game && game.status === 'Not Started') {
            setIsUpcoming(true);
        }

        // Check if this is an NBA game
        if (game && game.league === 'NBA') {
            setIsNBAGame(true);
        }
    }, [game]);

    // If we don't have any game data at all, show a generic message
    if (!game) {
        return (
            <div className="event-details">
                <h1 className="event-details-title">Event Details</h1>
                <div className="no-details">
                    <p>Event details are currently unavailable.</p>
                    <p>Stay tuned for updates!</p>
                </div>
            </div>
        );
    }

    // For upcoming games or NBA games, show a specialized view
    if (isUpcoming || isNBAGame || !eventStats || !eventStats.eventstats || eventStats.eventstats.length === 0) {
        return (
            <div className="event-details">
                <h1 className="event-details-title">{`${game.home_team} vs ${game.away_team}`}</h1>
                
                {/* Upcoming Game Banner */}
                {isUpcoming && (
                    <div className="upcoming-game-banner">
                        <span className="upcoming-label">Upcoming Match</span>
                        {game.date && (
                            <span className="game-date">
                                {new Date(game.date).toLocaleDateString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </span>
                        )}
                        {game.time && <span className="game-time">Kickoff: {game.time}</span>}
                        {game.venue && <span className="game-venue">Venue: {game.venue}</span>}
                    </div>
                )}

                {/* NBA Game Banner - show for completed NBA games */}
                {isNBAGame && !isUpcoming && (
                    <div className="nba-game-banner">
                        <span className="nba-label">Game Details</span>
                        {game.date && (
                            <span className="game-date">
                                {new Date(game.date).toLocaleDateString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </span>
                        )}
                        {game.status && <span className="game-status">Status: {game.status}</span>}
                        {game.venue && <span className="game-venue">Venue: {game.venue}</span>}
                    </div>
                )}

                {/* Centered Game Card */}
                <div className="centered-card">
                    <div className="game-card">
                        <div className="game-info">
                            <span className="team">
                                <img
                                    src={game.home_team_badge}
                                    alt={`${game.home_team} Badge`}
                                    className="team-badge"
                                />
                                <strong>{game.home_team}</strong>
                                {!isUpcoming && game.home_score !== undefined && (
                                    <span className="team-score">
                                        {game.home_score}
                                    </span>
                                )}
                            </span>
                            {prediction && (
                                <div className="win-prob-container">
                                    <span className="prob-label">Win Prob.</span>
                                    <span
                                        className={`win-prob home ${
                                            prediction.home_win_prob > prediction.away_win_prob
                                                ? 'favored'
                                                : ''
                                        }`}
                                    >
                                        {prediction.home_win_prob !== undefined
                                            ? `${Math.round(prediction.home_win_prob * 100)}%`
                                            : 'N/A'}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="game-divider"></div>
                        <div className="game-info">
                            <span className="team">
                                <img
                                    src={game.away_team_badge}
                                    alt={`${game.away_team} Badge`}
                                    className="team-badge"
                                />
                                <strong>{game.away_team}</strong>
                                {!isUpcoming && game.away_score !== undefined && (
                                    <span className="team-score">
                                        {game.away_score}
                                    </span>
                                )}
                            </span>
                            {prediction && (
                                <div className="win-prob-container">
                                    <span
                                        className={`win-prob away ${
                                            prediction.away_win_prob > prediction.home_win_prob
                                                ? 'favored'
                                                : ''
                                        }`}
                                    >
                                        {prediction.away_win_prob !== undefined
                                            ? `${Math.round(prediction.away_win_prob * 100)}%`
                                            : 'N/A'}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="game-details">
                            <span className="league-name league-left">
                                {formatLeagueName(game.league)}
                            </span>
                            {prediction && (
                                <span className="draw-prob">
                                    Draw:{' '}
                                    {prediction.draw_prob !== undefined
                                        ? `${Math.round(prediction.draw_prob * 100)}%`
                                        : 'N/A'}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {/* YouTube Video Section - show for completed games */}
                {!isUpcoming && game.video && (
                    <div className="video-section">
                        {!videoError ? (
                            <div className="video-wrapper">
                                <iframe
                                    src={getYouTubeEmbedUrl(game.video)}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    onError={() => setVideoError(true)}
                                ></iframe>
                            </div>
                        ) : (
                            <div className="video-error">
                                <p>Unable to load video. <a href={game.video} target="_blank" rel="noopener noreferrer">Watch on YouTube</a></p>
                            </div>
                        )}
                    </div>
                )}

                {/* Game Preview Section */}
                <div className="game-preview">
                    <h2>{isUpcoming ? "Match Preview" : "Game Summary"}</h2>
                    <p>
                        {isUpcoming ? (
                            <>
                                This match between {game.home_team} and {game.away_team} is scheduled to take place
                                {game.venue ? ` at ${game.venue}` : ''}.
                                {prediction ? 
                                    ` Based on our predictions, ${
                                        prediction.home_win_prob > prediction.away_win_prob 
                                        ? game.home_team 
                                        : prediction.away_win_prob > prediction.home_win_prob 
                                            ? game.away_team 
                                            : "neither team"
                                    } has the edge in this matchup.` 
                                    : ''}
                            </>
                        ) : (
                            <>
                                {game.home_team} {game.home_score !== undefined ? game.home_score : '?'} - {game.away_score !== undefined ? game.away_score : '?'} {game.away_team}
                                {game.venue ? ` at ${game.venue}` : ''}.
                                {prediction ? 
                                    ` Our model predicted ${
                                        prediction.home_win_prob > prediction.away_win_prob 
                                        ? game.home_team 
                                        : prediction.away_win_prob > prediction.home_win_prob 
                                            ? game.away_team 
                                            : "a draw"
                                    } as the likely outcome.` 
                                    : ''}
                            </>
                        )}
                    </p>
                </div>

                
            </div>
        );
    }

    // Original code for completed games with stats (Premier League games)
    const possessionStat = eventStats.eventstats.find((stat) => stat.strStat === 'Ball Possession');
    const stats = eventStats.eventstats.filter((stat) => stat.strStat !== 'Ball Possession');

    const getWinnerClass = (value, opposingValue) => {
        const valueFloat = parseFloat(value);
        const opposingValueFloat = parseFloat(opposingValue);
        if (valueFloat > opposingValueFloat) return 'highlight';
        return ''; // No highlight for the losing team
    };

    const embedUrl = game.video ? getYouTubeEmbedUrl(game.video) : null;

    return (
        <div className="event-details">
            <h1 className="event-details-title">{`${game.home_team} vs ${game.away_team}`}</h1>

            {/* Centered Game Card */}
            <div className="centered-card">
                <div className="game-card">
                    <div className="game-info">
                        <span className="team">
                            <img
                                src={game.home_team_badge}
                                alt={`${game.home_team} Badge`}
                                className="team-badge"
                            />
                            <strong>{game.home_team}</strong>
                            <span className="team-score">
                                {game.home_score !== undefined ? game.home_score : '?'}
                            </span>
                        </span>
                        {prediction && (
                            <div className="win-prob-container">
                                <span className="prob-label">Win Prob.</span>
                                <span
                                    className={`win-prob home ${
                                        prediction.home_win_prob > prediction.away_win_prob
                                            ? 'favored'
                                            : ''
                                    }`}
                                >
                                    {prediction.home_win_prob !== undefined
                                        ? `${Math.round(prediction.home_win_prob * 100)}%`
                                        : 'N/A'}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="game-divider"></div>
                    <div className="game-info">
                        <span className="team">
                            <img
                                src={game.away_team_badge}
                                alt={`${game.away_team} Badge`}
                                className="team-badge"
                            />
                            <strong>{game.away_team}</strong>
                            <span className="team-score">
                                {game.away_score !== undefined ? game.away_score : '?'}
                            </span>
                        </span>
                        {prediction && (
                            <div className="win-prob-container">
                                <span
                                    className={`win-prob away ${
                                        prediction.away_win_prob > prediction.home_win_prob
                                            ? 'favored'
                                            : ''
                                    }`}
                                >
                                    {prediction.away_win_prob !== undefined
                                        ? `${Math.round(prediction.away_win_prob * 100)}%`
                                        : 'N/A'}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="game-details">
                        <span className="league-name league-left">
                            {formatLeagueName(game.league)}
                        </span>
                        {prediction && (
                            <span className="draw-prob">
                                Draw:{' '}
                                {prediction.draw_prob !== undefined
                                    ? `${Math.round(prediction.draw_prob * 100)}%`
                                    : 'N/A'}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            {/* YouTube Video Section */}
            {embedUrl && (
                <div className="video-section">
                    {!videoError ? (
                        <div className="video-wrapper">
                            <iframe
                                src={embedUrl}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                onError={() => setVideoError(true)}
                            ></iframe>
                        </div>
                    ) : (
                        <div className="video-error">
                            <p>Unable to load video. <a href={game.video} target="_blank" rel="noopener noreferrer">Watch on YouTube</a></p>
                        </div>
                    )}
                </div>
            )}

            {/* Event Stats */}
            <h2 className="event-title">Stats</h2>
            <div className="stats-card">
                {/* Ball Possession */}
                {possessionStat && (
                    <div className="possession-container">
                        <h2 className="possession-label">Ball Possession</h2>
                        <div className="possession-row">
                            <div className="possession-bar">
                                <div
                                    className="bar home"
                                    style={{ width: `${possessionStat.intHome}%` }}
                                >
                                    <span className="possession-percentage">
                                        {possessionStat.intHome}%
                                    </span>
                                </div>
                                <div
                                    className="bar away"
                                    style={{ width: `${possessionStat.intAway}%` }}
                                >
                                    <span className="possession-percentage">
                                        {possessionStat.intAway}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Other Stats */}
                <div className="stats-list">
                    {stats.map((stat, index) => (
                        <div key={index} className="stat-row">
                            <span
                                className={`team-value home ${getWinnerClass(
                                    stat.intHome,
                                    stat.intAway
                                )}`}
                            >
                                {stat.intHome}
                            </span>
                            <span className="stat-name">{stat.strStat}</span>
                            <span
                                className={`team-value away ${getWinnerClass(
                                    stat.intAway,
                                    stat.intHome
                                )}`}
                            >
                                {stat.intAway}
                            </span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Lineup Section */}
            {eventLineup && eventLineup.lineup && (
                <div className="lineups-container">
                    <div className="lineup-team">
                        <div className="lineup-header">
                            <img src={game.home_team_badge} alt={`${game.home_team} Badge`} className="team-badge" />
                            <h2>{game.home_team}</h2>
                        </div>
                        <div className="lineup-players">
                            {eventLineup.lineup
                                .filter(player => player.strTeam === game.home_team)
                                .map((player, index) => (
                                    <div key={index} className="player-row">
                                        <div className="player-primary">
                                            <img src={player.strThumb} alt={player.strPlayer} className="player-thumb" />
                                            <span className="player-name">{player.strPlayer}</span>
                                        </div>
                                        <div className="player-secondary">
                                            <span className="player-position">{player.strPosition}</span>
                                            <span className="player-number">#{player.intSquadNumber}</span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="lineup-team">
                        <div className="lineup-header">
                            <img src={game.away_team_badge} alt={`${game.away_team} Badge`} className="team-badge" />
                            <h2>{game.away_team}</h2>
                        </div>
                        <div className="lineup-players">
                            {eventLineup.lineup
                                .filter(player => player.strTeam === game.away_team)
                                .map((player, index) => (
                                    <div key={index} className="player-row">
                                        <div className="player-primary">
                                            <img src={player.strThumb} alt={player.strPlayer} className="player-thumb" />
                                            <span className="player-name">{player.strPlayer}</span>
                                        </div>
                                        <div className="player-secondary">
                                            <span className="player-position">{player.strPosition}</span>
                                            <span className="player-number">#{player.intSquadNumber}</span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventDetails;
