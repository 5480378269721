import React, { useState, useEffect } from 'react';
import { fetchGames, fetchPredictions, fetchEventStats, fetchEventLineup } from './api';
import './GameList.css';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';

const formatLeagueName = (leagueName) => {
    if (leagueName === 'PremierLeague') {
        return 'Premier League';
    }
    return leagueName;
};

const GameList = () => {
    const [games, setGames] = useState([]);
    const [predictions, setPredictions] = useState([]);
    const [showScores, setShowScores] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filterDate, setFilterDate] = useState(queryParams.get('date') || new Date().toISOString().split('T')[0]);
    const [filterTeam, setFilterTeam] = useState('');
    const [filteredGames, setFilteredGames] = useState([]);
    const [filterLeague, setFilterLeague] = useState(queryParams.get('league') || '');
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            const gameData = await fetchGames();
            const startDate = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
            const endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
            const predictionData = await fetchPredictions(startDate, endDate);

            if (Array.isArray(gameData)) setGames(gameData);
            if (Array.isArray(predictionData)) {
                setPredictions(predictionData);
            }
        };
        loadData();
    }, []);

    useEffect(() => {
        const filtered = games.filter((game) => {
            return (
                (filterDate ? game.date === filterDate : true) &&
                (filterTeam
                    ? game.home_team.toLowerCase().includes(filterTeam.toLowerCase()) ||
                      game.away_team.toLowerCase().includes(filterTeam.toLowerCase())
                    : true) &&
                (filterLeague ? game.league === filterLeague : true)
            );
        });
        setFilteredGames(filtered);
    }, [games, filterDate, filterTeam, filterLeague]);

    const handleDateChange = (selectedDate) => {
        setFilterDate(selectedDate);
        navigate(`?date=${selectedDate}&league=${filterLeague}`);
    };

    const handleLeagueChange = (e) => {
        const selectedLeague = e.target.value;
        setFilterLeague(selectedLeague);
        navigate(`?date=${filterDate}&league=${selectedLeague}`);
    };

    const handleTeamChange = (e) => {
        setFilterTeam(e.target.value);
    };

    const toggleShowScores = () => {
        setShowScores(!showScores);
    };

    const changeDate = (offset) => {
        const currentDate = filterDate ? new Date(filterDate) : new Date();
        currentDate.setDate(currentDate.getDate() + offset);
        const newDate = currentDate.toISOString().split('T')[0];
        setFilterDate(newDate);
        navigate(`?date=${newDate}&league=${filterLeague}`);
    };

    const findPrediction = (game) => {
        return predictions.find(
            (prediction) =>
                prediction.home_team === game.home_team &&
                prediction.away_team === game.away_team &&
                moment(prediction.date).isSame(game.date, 'day')
        );
    };

    const getHighlightClass = (homeProb, awayProb) => {
        if (homeProb > awayProb) return 'highlight-home';
        if (awayProb > homeProb) return 'highlight-away';
        return '';
    };

    const handleGameClick = async (eventId) => {
        try {
            const eventStats = await fetchEventStats(eventId);
            const eventLineup = await fetchEventLineup(eventId);
            const gameData = games.find(game => game.event_id === eventId);
            const prediction = gameData ? findPrediction(gameData) : null;

            navigate(`/event/${eventId}`, { state: { eventStats, eventLineup, game: gameData, prediction } });
        } catch (error) {
            console.error('Failed to fetch event details:', error.message);
            alert('Unable to fetch event details. Please try again later.');
        }
    };

    return (
        <div className="game-list-container">
            <h1 className="game-list-title">Games</h1>
            <div className="filters">
                <div className="date-filter">
                    <button className="date-nav-button" onClick={() => changeDate(-1)}>
                        <BsArrowLeft />
                    </button>
                    <input type="date" value={filterDate} onChange={(e) => handleDateChange(e.target.value)} />
                    <button className="date-nav-button" onClick={() => changeDate(1)}>
                        <BsArrowRight />
                    </button>
                </div>
                <input type="text" placeholder="Filter by team..." value={filterTeam} onChange={handleTeamChange} />
                <button onClick={toggleShowScores} className="score-toggle">
                    {showScores ? 'Hide Scores' : 'Show Scores'}
                </button>
            </div>
            <div className="league-filter">
                <select value={filterLeague} onChange={handleLeagueChange}>
                    <option value="">All Leagues</option>
                    <option value="NBA">NBA</option>
                    <option value="PremierLeague">Premier League</option>
                </select>
            </div>
            {Array.isArray(filteredGames) && filteredGames.length > 0 ? (
                <ul className="list-group game-list-group">
                    {filteredGames.map((game, index) => {
                        const prediction = findPrediction(game);
                        const highlightClass =
                            prediction && getHighlightClass(prediction.home_win_prob, prediction.away_win_prob);

                        return (
                            <li
                                key={index}
                                className={`list-group-item game-list-group-item ${highlightClass || ''}`}
                                onClick={() => handleGameClick(game.event_id)}
                            >
                                <div className="game-card">
                                    <div className="game-info">
                                        <span className="team">
                                            <img
                                                src={game.home_team_badge}
                                                alt={`${game.home_team} Badge`}
                                                className="team-badge"
                                            />
                                            <strong>{game.home_team}</strong>
                                            <span className={`team-score ${!showScores ? 'hidden' : ''}`}>
                                                {showScores ? game.home_score : '?'}
                                            </span>
                                        </span>
                                        {prediction && (
                                            <div className="win-prob-container">
                                                <span className="prob-label">Win Prob.</span>
                                                <span
                                                    className={`win-prob home ${
                                                        highlightClass === 'highlight-home' ? 'favored' : ''
                                                    }`}
                                                >
                                                    {`${Math.round(prediction.home_win_prob * 100)}%`}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="game-divider"></div>
                                    <div className="game-info">
                                        <span className="team">
                                            <img
                                                src={game.away_team_badge}
                                                alt={`${game.away_team} Badge`}
                                                className="team-badge"
                                            />
                                            <strong>{game.away_team}</strong>
                                            <span className={`team-score ${!showScores ? 'hidden' : ''}`}>
                                                {showScores ? game.away_score : '?'}
                                            </span>
                                        </span>
                                        {prediction && (
                                            <div className="win-prob-container">
                                                <span
                                                    className={`win-prob away ${
                                                        highlightClass === 'highlight-away' ? 'favored' : ''
                                                    }`}
                                                >
                                                    {`${Math.round(prediction.away_win_prob * 100)}%`}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="game-details">
                                        <span className="league-name league-left">
                                            {formatLeagueName(game.league)}
                                        </span>
                                        {prediction && (
                                            <span className="draw-prob">
                                                Draw: {`${Math.round(prediction.draw_prob * 100)}%`}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p>No games to display.</p>
            )}
        </div>
    );
};

export default GameList;
